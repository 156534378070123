var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('div',{staticClass:"page-wrapper-table-header-left"},[_c('base-input',{staticClass:"search",attrs:{"type":"search","prepend-icon":"far fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(
            !_vm.filterOrganization &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          )?_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}}):_vm._e(),(
            !_vm.filterPackage &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PACKAGES)
          )?_c('package-selector',{on:{"packageChanged":(packageId) => (_vm.selectedPackage = packageId)}}):_vm._e(),_c('base-input',{attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
              allowInput: true,
              mode: 'range',
              locale: _vm.flatPickrLocale,
            },"placeholder":_vm.$t('SUBSCRIPTIONS.START_TIME')},model:{value:(_vm.startDateRange),callback:function ($$v) {_vm.startDateRange=$$v},expression:"startDateRange"}})],1),_c('base-input',{attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
              allowInput: true,
              mode: 'range',
              locale: _vm.flatPickrLocale,
            },"placeholder":_vm.$t('SUBSCRIPTIONS.END_TIME')},model:{value:(_vm.endDateRange),callback:function ($$v) {_vm.endDateRange=$$v},expression:"endDateRange"}})],1)],1),_c('div',{staticClass:"page-wrapper-table-header-right"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1)]),(_vm.subscriptions.length > 0)?_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.subscriptions},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.isSubscriptionExpired(row))?_c('span',{staticClass:"text-danger text-bold"},[_c('img',{attrs:{"src":"/img/kw-times.svg","alt":"icon"}})]):_vm._e(),(!_vm.isSubscriptionExpired(row))?_c('span',{staticClass:"text-success text-bold"},[_c('img',{attrs:{"src":"/img/kw-green-check.svg","alt":"icon"}})]):_vm._e()]}}],null,false,3745795741)}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CODE'),"prop":"code","sortable":"custom","min-width":"200"}}),_c('el-table-column',{attrs:{"sortable":"custom","label":_vm.$t('SUBSCRIPTIONS.START_TIME'),"prop":"start_time","min-width":"180"}}),_c('el-table-column',{attrs:{"sortable":"custom","label":_vm.$t('SUBSCRIPTIONS.END_TIME'),"prop":"end_time","min-width":"180"}}),(
              !_vm.filterPackage &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PACKAGES)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PACKAGE'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(row.package ? row.package.name : null)+" ")])]}}],null,false,2477336311)}):_vm._e(),(
              !_vm.filterOrganization &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"180"}}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"70"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"far fa-ellipsis-v"})]),_c('el-dropdown-menu',{staticClass:"actions-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_SUBSCRIPTIONS)
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action view",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewSubscription(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-eye.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("COMMON.VIEW_DETAILS")))])])]):_vm._e(),(
                      _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SUBSCRIPTIONS)
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action edit",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editSubscription(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-edit.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])])]):_vm._e(),(
                      _vm.$currentUserCan(
                        _vm.$permissions.PERM_DELETE_SUBSCRIPTIONS
                      ) && false
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action delete",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteSubscription(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/img/kw-delete.svg","alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])])]):_vm._e()],1)],1)],1)}}],null,false,3261719117)})],1)],1)]):_vm._e(),(_vm.subscriptions.length > 0)?_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1):_vm._e()]),(_vm.subscriptions.length === 0)?_c('div',{staticClass:"no-data"},[_c('div',{staticClass:"no-data-inner"},[_c('img',{attrs:{"src":"/img/kw-empty-box.svg","alt":"icon"}}),_c('p',[_vm._v(_vm._s(_vm.$t("SUBSCRIPTIONS.NO_DATA")))]),(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_SUBSCRIPTIONS))?_c('base-button',{staticClass:"kw-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.onAddSubscription}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.$t("SUBSCRIPTIONS.ADD_SUBSCRIPTION")))])]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }